export const FeatureFlag = {
  // add your feature flags here, the value on the right is the key defined in the LaunchDarkly dashboard for example:
  MAX_OWNED_WORKSPACES: 'max_owned_workspaces',
  PDF_PROPERTY_TYPE: 'pdf-property-type',
  WHISPER_TOOL: 'whisper-tool',
  EMPTY_TEMPLATE_UUID: 'empty-project-template-uuid',
  BING_SEARCH_ENABLED: 'show-bing-search',
  URL_PROPERTY_TOOL: 'url-property-tool',
  AB_PROPERTY_EDITOR: 'ab-property-editor',
  GO_TOOL_ENABLED: 'show-go-model',
  /**
   * If true, then the 'worker' role is available. Users can then be created as
   * workers, and updated to have this role.
   */
  WORK_ASSIGNENT: 'work-assignment',
  // When true, the user can see the AWS OCR tool
  AMAZON_TEXTRACT: 'amazon-textract-tool',
  /**
   * When true, all users will see the Intercom chat widget. When false, only
   * pro and enterprise users will see the chat widget.
   */
  INTERCOM_FOR_ALL_USERS: 'intercom-for-all-users',
  ASK_GO_PROJECT_CREATION: 'ask-go-project-creation',
  PDF_DOWNLOAD: 'pdf-download',
  /**
   * When true, non enterprise users will see the total estimated value of the tokens for the given plan.
   */
  PLAN_TOKENS_VALUE: 'plan-tokens-value',
  /**
   * We show/hide UI elements on the frontend depending on a user's permissions in
   * the current project/workspace. e.g. a project 'reader' cannot create entities
   * so can't see the 'Add Entity' button.
   *
   * When enabled, the frontend will believe that the current user has every
   * permission, and so will render UI elements that will send API requests that
   * might fail.
   *
   * This flag is to be used when we want to test that the backend is correctly
   * blocking requests.
   */
  ENABLE_ALL_FRONTEND_ACTIONS: 'enable-all-frontend-actions',
  AI_MODEL_VERTEX_ANTHROPIC: 'ai-model-vertex-anthropic',
  AI_MODEL_AZURE_OPENAI: 'ai-model-azure-openai',
  OPENAI_O1: 'open-ai-o-1',
  XLSX_SPLIT: 'xlsx-split',
  MARKDOWN_EDITOR: 'markdown-editor',
  JSON_EDITOR: 'json-editor',
  /**
   * When enabled, users will be able to create file_collection properties on
   * the frontend, and can filter collection children when passing a
   * collection as an input to a tool.
   */
  INPUT_COLLECTIONS: 'input-collections',
} as const

export type FeatureFlag = (typeof FeatureFlag)[keyof typeof FeatureFlag]
