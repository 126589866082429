<script setup lang="ts">
import NavigationList from '@/uiKit/NavigationList.vue'
import NavigationListItem from '@/uiKit/NavigationListItem.vue'
import IconButton from '@/uiKit/IconButton.vue'
import { useRouter } from 'vue-router'
import V7Logo from '@/illustrations/v7-logo.svg'

const router = useRouter()

const onClickBackButton = () => {
  router.push({ name: 'WorkspaceProjects' })
}
</script>

<template>
  <div class="flex h-full w-[240px] flex-col border-r border-border-subtle bg-surface-secondary">
    <div class="px-3 py-4">
      <IconButton
        icon="arrow-left"
        size="lg"
        data-test="back-button"
        variant="transparent"
        @click="onClickBackButton()"
      />
    </div>
    <div class="flex grow flex-col justify-between">
      <div>
        <NavigationList>
          <template #header>Settings</template>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsGeneral' }">
            General
          </NavigationListItem>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsProfile' }">
            Profile
          </NavigationListItem>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsUsers' }"> Users </NavigationListItem>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsPlans' }"> Plans </NavigationListItem>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsBilling' }">
            Billing and Usage
          </NavigationListItem>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsApiKeys' }">
            API Keys
          </NavigationListItem>
          <NavigationListItem :to="{ name: 'WorkspaceSettingsAiModels' }">
            AI Models
          </NavigationListItem>
        </NavigationList>
      </div>
      <div class="pb-4">
        <V7Logo />
      </div>
    </div>
  </div>
</template>
