import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { computed } from 'vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'
import { useLibraryStore, type LibraryItem } from '../Library/libraryStore'
import { countFilters } from './Filters/utils'
import { useProject, type Property, type PropertyInput } from './useProject'
import { useProperty } from './useProperty'
import { useSubProject } from './useSubProject'

export type ModelInputItem = {
  id: string
  data:
    | (Property & { group: 'Properties'; filters?: PropertyInput['filters']; filterCount: number })
    | (LibraryItem & { group: 'Library'; propertyId: string })
}

/**
 * AI model properties take other properties as inputs. This composable provides:
 * 1. The list of all properties and library items that can be used as inputs on the current view
 * 2. The list of properties and library items that are currently selected as inputs
 */
export const useModelInputs = () => {
  const propertyStore = useProperty()
  const projectStore = useProject()
  const subProjectStore = useSubProject()
  const inputCollectionsEnabled = useFeatureFlags(FeatureFlag.INPUT_COLLECTIONS)

  const libraryStore = useLibraryStore()

  const libraryInputs = computed<ModelInputItem[]>(() =>
    libraryStore.libraryItems.map<ModelInputItem>((item) => {
      assertIsNotNullOrUndefined(libraryStore.library, 'Library is not loaded')
      const itemType = item.type
      const propertyId =
        itemType === 'file'
          ? libraryStore.library.fileProperty.id
          : libraryStore.library.textProperty.id

      return {
        id: item.id,
        data: { ...item, group: 'Library', propertyId },
      }
    }),
  )

  const propertyInputs = computed<ModelInputItem[]>(() => {
    const propertyIsIncluded = (p: Property): boolean =>
      propertyStore.editedInputs.some(({ propertyId }) => p.id === propertyId)

    // We should only show properties that are in the main view or the active view
    const propertiesToShow = propertyStore.otherProperties.filter((p) => {
      const isInMainView = projectStore.mainView?.view.propertyIds?.includes(p.id)
      const isInActiveView = projectStore.activeView?.view.propertyIds?.includes(p.id)
      const isAlreadySelectedAsInput = propertyIsIncluded(p)
      // skip properties that are collection or pdf types, cause if used as input, it will cause an error
      // see GO-2248
      if (!inputCollectionsEnabled.value && (p.type === 'collection' || p.type === 'pdf'))
        return false
      return isInMainView || isInActiveView || isAlreadySelectedAsInput
    })

    const inputs = [...propertiesToShow, ...subProjectStore.properties]
      .toSorted((a, b) => (propertyIsIncluded(a) && !propertyIsIncluded(b) ? -1 : 1))
      .reduce<ModelInputItem[]>((acc, p) => {
        if (p.id === propertyStore.property?.id) return acc

        const matchingInput = propertyStore.editedInputs.find((e) => e.propertyId === p.id)

        const asInputItem: ModelInputItem = {
          id: p.id,
          data: {
            ...p,
            group: 'Properties',
            filters: matchingInput?.filters,
            filterCount: countFilters(matchingInput?.filters),
          },
        }

        return [...acc, asInputItem]
      }, [])

    return inputs
  })

  const inputIdOptions = computed(() => [...propertyInputs.value, ...libraryInputs.value])

  const hasLibraryInputs = computed(() => libraryInputs.value.length > 0)

  const selectedLibraryItems = computed(() =>
    libraryInputs.value.filter((i) =>
      propertyStore.editedInputs.some((e) => e.entityId === i.data.id),
    ),
  )

  const selectedProperties = computed(() =>
    propertyInputs.value.filter((i) =>
      propertyStore.editedInputs.some((e) => e.propertyId === i.data.id),
    ),
  )

  const selectedInputIdOptions = computed(() => [
    ...selectedLibraryItems.value,
    ...selectedProperties.value,
  ])

  return {
    inputIdOptions,
    selectedInputIdOptions,
    hasLibraryInputs,
  }
}
