<script setup lang="ts">
import DividerLine from '@/uiKit/DividerLine.vue'
import WorkspaceSettingsAiModelsProvider from './WorkspaceSettingsAiModelsProvider.vue'
import { useModelProviders } from './modelProvidersStore'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import DotIllustration from '@/illustrations/dots.svg'
import { useBilling } from '../Billing/useBilling'
import { computed } from 'vue'
import { setIntegrationEnabled } from '@/backend/setIntegrationEnabled'
import type { ToolProvider } from '@/backend/types'
import { usePermissionsStore } from '../IdentityAndAccess/permissionsStore'
import IconSprite from '@/uiKit/IconSprite.vue'
import { FeatureFlag } from '../App/featureFlags'
import { useFeatureFlags } from '../App/useFeatureFlags'

const props = defineProps<{ workspaceId: string }>()

const billingStore = useBilling()
const isEditingEnabled = computed(
  () =>
    billingStore.activePlan?.name === 'enterprise' &&
    permissionsStore.workspacePermissions.manage_tool_integrations,
)

const providerStore = useModelProviders()
const onToggleProvider = async (provider: ToolProvider) => {
  const newEnabled = !providerStore.providers[provider].enabled
  providerStore.providers[provider].enabled = newEnabled
  const res = await setIntegrationEnabled({
    enabled: newEnabled,
    provider,
    workspaceId: props.workspaceId,
  })

  if (!res.ok) {
    providerStore.providers[provider].enabled = !newEnabled
  }
}

const permissionsStore = usePermissionsStore()

const canUseAzure = useFeatureFlags(FeatureFlag.AI_MODEL_AZURE_OPENAI)
const canUseVertex = useFeatureFlags(FeatureFlag.AI_MODEL_VERTEX_ANTHROPIC)
</script>

<template>
  <div class="mx-auto my-16 w-full max-w-[760px] px-4">
    <h1 class="text-xl-18px-bold text-text">AI Models</h1>
    <p class="text-sm-12px-light text-text-subtle">
      Connect models to your own API Key instead of consuming Go Tokens, and restrict which models
      are shown in the list of AI tools.
    </p>
    <DividerLine
      class="my-8"
      color="subtle"
      direction="horizontal"
    />
    <div
      v-if="!(billingStore.activePlan?.name === 'enterprise')"
      class="mb-2 flex h-16 items-center justify-between gap-4 rounded-xl bg-background-gray-subtlest pr-4"
    >
      <DotIllustration />
      <div class="grow text-md-13px-default text-text">
        {{
          permissionsStore.workspacePermissions.manage_tool_integrations
            ? 'Model management is only available to Enterprise users.'
            : "You don't have permission to manage model integrations."
        }}
      </div>
      <DarwinButton
        v-if="permissionsStore.workspacePermissions.manage_tool_integrations"
        size="sm"
        variant="black"
        target="_blank"
        href="http://www.v7labs.com/contact-sales-go?utm_campaign=%5BProduct%5D%20Go%20Plan%20Upg[…]m_medium=go&utm_term=contact-sales&utm_content=ai-models-menu"
        >Contact Sales</DarwinButton
      >
    </div>
    <WorkspaceSettingsAiModelsProvider
      :enabled="providerStore.providers.open_ai.enabled"
      :disable-editing="!isEditingEnabled"
      provider="open_ai"
      @toggle="onToggleProvider('open_ai')"
    />
    <template v-if="canUseAzure">
      <DividerLine
        class="pl-24"
        color="subtle"
      />
      <WorkspaceSettingsAiModelsProvider
        :enabled="providerStore.providers.azure_open_ai.enabled"
        :disable-editing="!isEditingEnabled"
        provider="azure_open_ai"
        disable-custom-key
        @toggle="onToggleProvider('azure_open_ai')"
        ><span class="inline-flex items-center gap-1">
          OpenAI (via
          <IconSprite
            class="size-4 rounded-corner-4 bg-[#4385F5] text-[white]"
            size="xs"
            icon="company-azure"
          />
          Azure)
        </span>
      </WorkspaceSettingsAiModelsProvider>
    </template>
    <DividerLine
      class="pl-24"
      color="subtle"
    />
    <WorkspaceSettingsAiModelsProvider
      provider="google_ai"
      :disable-editing="!isEditingEnabled"
      :enabled="providerStore.providers.google_ai.enabled"
      @toggle="onToggleProvider('google_ai')"
    >
      <span class="inline-flex items-center gap-1">
        Google (via
        <IconSprite
          class="size-4 rounded-corner-4 bg-[#4385F5] text-[white]"
          size="lg"
          icon="company-vertex"
        />
        Vertex AI)
      </span>
    </WorkspaceSettingsAiModelsProvider>
    <DividerLine
      class="pl-24"
      color="subtle"
    />
    <WorkspaceSettingsAiModelsProvider
      :enabled="providerStore.providers.anthropic.enabled"
      :disable-editing="!isEditingEnabled"
      provider="anthropic"
      @toggle="onToggleProvider('anthropic')"
    />
    <template v-if="canUseVertex">
      <DividerLine
        class="pl-24"
        color="subtle"
      />
      <WorkspaceSettingsAiModelsProvider
        :enabled="providerStore.providers.vertex_ai_claude.enabled"
        :disable-editing="!isEditingEnabled"
        provider="vertex_ai_claude"
        disable-custom-key
        @toggle="onToggleProvider('vertex_ai_claude')"
      >
        <span class="inline-flex items-center gap-1">
          Anthropic (via
          <IconSprite
            class="size-4 rounded-corner-4 bg-[#4385F5] text-[white]"
            size="lg"
            icon="company-vertex"
          />
          Vertex AI)
        </span>
      </WorkspaceSettingsAiModelsProvider>
    </template>
  </div>
</template>
