<script setup lang="ts">
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import TextField from '@/uiKit/TextField.vue'
import { reactive, ref } from 'vue'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { toast } from '@/shared/toast'
import { whenever } from '@vueuse/core'

const userStore = useUser()
const form = ref<HTMLFormElement>()

type FormFields = typeof formFields
const formFields = reactive({
  firstName: userStore.user?.firstName || '',
  lastName: userStore.user?.lastName || '',
})

// Update local values when source data from the store changes
whenever(
  () => userStore.user,
  (user) => {
    if (!isFocused('firstName')) {
      formFields.firstName = user.firstName || ''
    }

    if (!isFocused('lastName')) {
      formFields.lastName = user.lastName || ''
    }
  },
)

/**
 * Check if form field is currently in focus
 *
 * Helpful in the following scenario:
 * 1. User changes firstName, tabs away, network request kicks off
 * 2. User changes lastName, doesn't submit changes
 * 3. First network request comes back with full user data
 * 4. Value of lastName gets changed while user is still editing it
 *
 * By checking if a form field is focused, we can prevent form updates
 */
function isFocused(field: keyof FormFields): boolean {
  return document.activeElement === form.value?.elements?.namedItem(field)
}

/** Check if local form data matches user data from the store */
function isUnchanged() {
  const fieldNames = Object.keys(formFields) as (keyof FormFields)[]
  return fieldNames.every((fieldName) => userStore.user?.[fieldName] === formFields[fieldName])
}

async function onSubmit() {
  assertIsNotNullOrUndefined(userStore.user)

  if (isUnchanged()) {
    return
  }

  try {
    await userStore.update({ ...formFields })
    toast.success('Profile updated', { id: 'profileUpdateSuccess' })
  } catch {
    toast.error('Profile update failed', { id: 'profileUpdateError' })
  }
}
</script>

<template>
  <div class="mx-auto flex max-w-screen-md flex-col gap-8 px-4 py-16 lg:px-0">
    <div class="flex flex-col gap-1">
      <h1 class="text-xl-18px-bold text-text">Profile</h1>
      <p class="text-sm-12px-light text-text-subtle">
        This profile is your global profile, that is used in any team you are part of.
      </p>

      <form
        ref="form"
        data-test="profileForm"
        class="mt-8 flex flex-col gap-4"
        @submit.prevent="onSubmit"
      >
        <!-- Enables submission on enter keypress within input fields -->
        <input
          type="submit"
          hidden
        />
        <!-- Name row -->
        <div class="flex flex-col gap-4 md:flex-row">
          <TextField
            label="First name"
            name="firstName"
            placeholder="First name"
            size="md"
            :value="formFields.firstName"
            @input="formFields.firstName = $event"
            @blur="onSubmit"
          />
          <TextField
            label="Last name"
            name="lastName"
            placeholder="Last name"
            size="md"
            :value="formFields.lastName"
            @input="formFields.lastName = $event"
            @blur="onSubmit"
          />
        </div>
        <!-- Email row -->
        <div class="flex flex-col gap-4 md:flex-row">
          <TextField
            size="md"
            :disabled="true"
            label="Email address"
            placeholder="email"
            name="email"
            :value="userStore.user?.email"
          />

          <!-- Makes email address field precisely align with the firstName field -->
          <div class="w-full" />
        </div>
      </form>
    </div>
  </div>
</template>
