import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useBilling } from './useBilling'

/**
 * User actions that can trigger the paywall
 * Used to add context to analytics events
 */
export const PAYWALL_EVENT = {
  ADD_ENTITY: 'add_entity',
  ADD_MEMBER: 'add_member',
  ADD_PROPERTY: 'add_property',
  CSV_IMPORT: 'csv_import',
  RECALCULATE_ENTITIES: 'recalculate_entities',
  SEND_INVITATION: 'send_invitation',
} as const

export type PaywallEvent = (typeof PAYWALL_EVENT)[keyof typeof PAYWALL_EVENT]

/**
 * When a workspace reaches one of its limits, we show a paywall dialog
 * to push the user towards upgrading their plan.
 */
export const usePaywallStore = defineStore('paywall', () => {
  const dialogIsOpen = ref(false)
  const { captureAnalyticsEvent } = useAnalytics()
  const billing = useBilling()

  /**
   * Open a paywall dialog
   * @param metadata.action - Action that hit a limit which triggered the paywall
   */
  const open = (metadata: { action: PaywallEvent }) => {
    dialogIsOpen.value = true

    if (billing.activePlan?.name === 'free') {
      captureAnalyticsEvent(ANALYTICS_EVENT.FREE_PLAN_PAYWALL, metadata)
    }
  }

  /** Close the paywall dialog */
  const close = () => {
    dialogIsOpen.value = false
  }

  const isOpen = computed(() => dialogIsOpen.value)

  return {
    isOpen,
    close,
    open,
  }
})
